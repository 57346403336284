
// fichier FeuillesHebdo.js

import React, { useEffect, useState, useRef }                       from 'react'
import { collection, getDocs, query, where, addDoc, Timestamp }     from 'firebase/firestore'
import { signInWithEmailAndPassword }                               from 'firebase/auth'
import { db, auth }                                                 from "../../firebase.config"
import back                                                         from "../../assets/back.png"
import ReactSignatureCanvas                                         from 'react-signature-canvas'
import jsPDF                                                        from "jspdf"  
import html2canvas                                                  from "html2canvas"

function FeuillesHebdo({ uid, onReturn }) {

    const [feuillesRoute, setFeuillesRoute]                         = useState([])
    const [isModalOpen, setIsModalOpen]                             = useState(false)
    const [isModalPrintOpen, setIsModalPrintOpen]                   = useState(false)
    const [errorMsg, setErrorMsg]                                   = useState("")
    const [errorNoSignature, setErrorNoSignature]                   = useState("")
    const [password, setPassword]                                   = useState('')
    const [usersMap, setUsersMap]                                   = useState({})
    const [signatureData, setSignatureData]                         = useState(null)
    const [message, setMessage]                                     = useState("")
    const [isDownloadDisplay, setIsDownloadDisplay]                 = useState(false)
    const [downloadDone, setDownloadDone]                           = useState(false)
    const [isFinalModal, setIsFinalModal]                           = useState(false)
    const [modalError, setModalError]                               = useState(false)
    const [noFicheHebdoLastWeek, setNoFicheHebdoLastWeek]           = useState(false) 
    const [isModalPrintOpenLastWeek, setIsModalPrintOpenLastWeek]   = useState(false)
    const [openModalLastWeek, setopenModalLastWeek]                 = useState(false)
    const [currentMissingDay, setCurrentMissingDay]                 = useState(null)
    const [motifsForDays, setMotifsForDays]                         = useState({})
    const [missingDaysQueue, setMissingDaysQueue]                   = useState([])
    const [isMotifModalOpen, setIsMotifModalOpen]                   = useState(false)
    const [isMotifModalOpenLastWeek, setIsMotifModalOpenLastWeek]   = useState(false)
    const signatureCanvasRef                                        = useRef({}) 
    const pageRef                                                   = useRef()

    const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'] 

    // Récupère les infos du user
    useEffect(() => {
        const fetchUsersData = async () => {
            const usersData = {}
            try {
                const usersSnapshot = await getDocs(collection(db, 'users'))

                usersSnapshot.forEach((doc) => {
                    usersData[doc.id] = doc.data()
                })

                setUsersMap(usersData)
            } 
            catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs : ", error)
            }
        }
    
        fetchUsersData()
    }, [])

    // Récupère les feuilles de routes du user
    useEffect(() => {
        if (!uid) return
        fetchFeuillesRoute()
        // eslint-disable-next-line
    }, [uid])

    const fetchFeuillesRoute = async () => {
        const feuillesRouteRef = collection(db, 'feuillesDeRoute')
        const q = query(feuillesRouteRef, where('userId', '==', uid))
        const querySnapshot = await getDocs(q)
    
        const feuillesRouteData = querySnapshot.docs.map(doc => ({
            id: doc.id, ...doc.data(),
        }))
    
        setFeuillesRoute(feuillesRouteData)
    }

    // Vérifie si le user a signé sa feuille hebdo de la semaine passée
    useEffect(() => {
        const fetchFeuilleHebdo = async () => {
            try {
                const today = new Date()

                // Récupère la date de la semaine passée
                const lastWeek = new Date(today.setDate(today.getDate() - 7))
                const lastWeekStart = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay())) // Début de la semaine passée (dimanche)
                const lastWeekEnd = new Date(lastWeekStart)
                lastWeekEnd.setDate(lastWeekStart.getDate() + 6) // Fin de la semaine passée (samedi)

                // Normalise les dates pour ignorer l'heure
                const normalizeDate = (date) => {
                    const newDate = new Date(date)
                    newDate.setHours(0, 0, 0, 0)  // Fixer l'heure à minuit
                    return newDate
                }

                const feuilleHebdo = collection(db, 'fdrSemaine')

                // Récupére toutes les feuilles hebdo du user
                const q = query(
                    feuilleHebdo,
                    where('userId', '==', uid) 
                )
                
                const querySnapshot = await getDocs(q)

                // Filtre les feuilles par date 
                const feuilleSemainePassee = querySnapshot.docs.some(doc => {
                    const feuilleDate = normalizeDate(doc.data().dateSemaine.toDate())
                    const isWithinLastWeek = feuilleDate >= normalizeDate(lastWeekStart) && feuilleDate <= normalizeDate(lastWeekEnd)
                    
                    return isWithinLastWeek
                })
 
                // Affiche la modale si aucune feuille signée n'a été trouvée pour la semaine passée
                if (!feuilleSemainePassee) {
                    //console.log("Feuille hebdo introuvable")
                    setNoFicheHebdoLastWeek(true)
                }
            }
            catch (error) {
                console.error('Erreur lors de la récupération de la feuille hebdomadaire :', error)
            }
        }

        fetchFeuilleHebdo()
    }, [uid])

    const formatDate = (date) => date?.toLocaleDateString('fr-FR', {
        day: '2-digit', month: '2-digit', year: 'numeric'
    })

    const getDayName = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000)
        return date.toLocaleDateString('fr-FR', { weekday: 'long' }).charAt(0).toUpperCase() + date.toLocaleDateString('fr-FR', { weekday: 'long' }).slice(1)
    } 

    const getStartOfWeek = (date) => {
        const day = date.getDay()
        const diffToMonday = day === 0 ? 6 : day - 1
        const startOfWeek = new Date(date)
        startOfWeek.setDate(date.getDate() - diffToMonday)
        startOfWeek.setHours(0, 0, 0, 0)
        return startOfWeek
    }

    const getEndOfWeek = (startOfWeek) => {
        const endOfWeek = new Date(startOfWeek)
        endOfWeek.setDate(startOfWeek.getDate() + 4)
        endOfWeek.setHours(23, 59, 59, 999)
        return endOfWeek
    }

    const groupStopsByWeek = () => {
        const currentWeek = {}
        const lastWeek = {}
        const weeks = {}

        feuillesRoute.forEach(feuille => {
            const date = new Date(feuille.date.seconds * 1000)
            const startOfWeek = getStartOfWeek(date)
            const endOfWeek = getEndOfWeek(startOfWeek)
            const weekKey = `${startOfWeek.toISOString().split('T')[0]}_${endOfWeek.toISOString().split('T')[0]}`
            
            if (!weeks[weekKey]) {
                weeks[weekKey] = { startOfWeek, endOfWeek, stopsByDay: {}, totalKmByDay: {}, motifsByDay: {}}
  
                days.forEach(day => {
                    weeks[weekKey].stopsByDay[day] = []
                    weeks[weekKey].totalKmByDay[day] = 0
                    weeks[weekKey].motifsByDay[day] = []
                })
            }

            const dayName = getDayName(feuille.date)

            if (weeks[weekKey].stopsByDay.hasOwnProperty(dayName)) {

                if (feuille.isVisitsStarted === false && feuille.motif) {
                    weeks[weekKey].motifsByDay[dayName].push(feuille.motif)
                }

                feuille?.stops?.forEach(stop => {
                    weeks[weekKey].stopsByDay[dayName].push({ ...stop, dayName })
                    weeks[weekKey].totalKmByDay[dayName] += stop.distance || 0
                })
            }

            if (isCurrentWeek(startOfWeek, endOfWeek)) { 
                currentWeek[weekKey] = weeks[weekKey]
            }
            
            if (isLastWeek(startOfWeek, endOfWeek)) {
                lastWeek[weekKey] = weeks[weekKey];
            }  
        })
        
        if (Object.keys(currentWeek).length === 0) {         
            const today = new Date()
            const startOfWeek = getStartOfWeek(today)
            const endOfWeek = getEndOfWeek(startOfWeek)
            const weekKey = `${startOfWeek.toISOString().split('T')[0]}_${endOfWeek.toISOString().split('T')[0]}`
    
            currentWeek[weekKey] = { startOfWeek, endOfWeek, stopsByDay: {}, totalKmByDay: {}, motifsByDay: {} }

            days.forEach(day => {
                currentWeek[weekKey].stopsByDay[day] = []
                currentWeek[weekKey].totalKmByDay[day] = 0
                currentWeek[weekKey].motifsByDay[day] = []
            })
        }
        return { currentWeek, lastWeek }
    }

    const isCurrentWeek = (startOfWeek, endOfWeek) => { 
        const today = new Date()
        const startOfWeekDate = new Date(startOfWeek)
        const endOfWeekDate = new Date(endOfWeek)
        return today >= startOfWeekDate && today <= endOfWeekDate
    }

    const isLastWeek = (startOfWeek, endOfWeek) => {
        const today = new Date()
        const lastWeekStart = getStartOfWeek(new Date(today.setDate(today.getDate() - 7)))
        const lastWeekEnd = getEndOfWeek(lastWeekStart)   
        return startOfWeek >= lastWeekStart && endOfWeek <= lastWeekEnd
    }
    
    const { currentWeek, lastWeek } = groupStopsByWeek()

    const getVisitCounts = () => {
        const currentWeekKey = Object.keys(currentWeek)[0]
        const currentLastWeek = Object.keys(lastWeek)[0]

        let totalVisits = 0
        let clientVisits = 0
        let prospectVisits = 0

        let totalVisitsLastWeek = 0
        let clientVisitsLastWeek = 0
        let prospectVisitsLastWeek = 0
    
        if (currentWeekKey) {
            const { stopsByDay } = currentWeek[currentWeekKey]
    
            days.forEach(day => {
                stopsByDay[day].forEach(stop => {
                    if (stop && stop.status) {
                        totalVisits++

                        if (stop.status === "Client") {
                            clientVisits++
                        } 
                        else if (stop.status === "Prospect") {
                            prospectVisits++
                        }
                    }
                })
            })
        }

        if (currentLastWeek) {
            const { stopsByDay } = lastWeek[currentLastWeek]
    
            days.forEach(day => {
                stopsByDay[day].forEach(stop => {
                    if (stop && stop.status) {
                        totalVisitsLastWeek++

                        if (stop.status === "Client") {
                            clientVisitsLastWeek++
                        } 
                        else if (stop.status === "Prospect") {
                            prospectVisitsLastWeek++
                        }
                    }
                })
            })
        }
    
        return { totalVisits, clientVisits, prospectVisits, totalVisitsLastWeek, clientVisitsLastWeek, prospectVisitsLastWeek }
    }

    const visitCounts = getVisitCounts()

    const formatDistance = (distance) => {
        if (distance < 1000) {
          return `${distance.toFixed(0)} m`
        }

        return `${(distance / 1000).toFixed(2)} km`
    }

    // Vérifie si on est vendredi soir pour autorisé le user à signer sa fiche hebdo
    const handleValidate = () => {
        const now = new Date()
        const day = now.getDay()
        const hours = now.getHours()
        
        if (day === 5 && hours >= 17 && hours <= 23) { // vendredi = 5 entre 17h et 23H 
            setIsModalOpen(true)
        }
        else {
            setModalError(true)
        }
    }

    // Autorisation par mot de passe - Semaine actuelle
    const handlePassword = async (e) => {
        e.preventDefault()
        
        try {
            const userCredential = await signInWithEmailAndPassword(auth, auth.currentUser.email, password)
            const user = userCredential.user
            
            if (user.uid === uid) {
                const today = new Date();
                const currentWeekStart = getStartOfWeek(today);
                const currentWeekEnd = getEndOfWeek(currentWeekStart);

                //console.log(currentWeekStart)

                // Récupérer les feuilles de la semaine actuelle
                const querySnapshot = await getDocs(query(
                    collection(db, 'feuillesDeRoute'),
                    where('userId', '==', uid),
                    where('date', '>=', Timestamp.fromDate(currentWeekStart)),
                    where('date', '<=', Timestamp.fromDate(currentWeekEnd))
                ));
            
                const existingDays = querySnapshot.docs.map(doc => formatDate2(doc.data().date.toDate()));
                const missingDays = [];

                for (let dayOffset = 0; dayOffset < 5; dayOffset++) {
                    const dayDate = new Date(currentWeekStart);
                    dayDate.setDate(currentWeekStart.getDate() + dayOffset);
                    
                    const formattedDate = formatDate2(dayDate);
                    if (!existingDays.includes(formattedDate)) {
                        missingDays.push(formattedDate);
                    }
                }

                if (missingDays.length > 0) {
                    setMissingDaysQueue(missingDays);
                    setCurrentMissingDay(missingDays[0]);
                    setMotifsForDays(Array(missingDays.length).fill(""));
                    setIsMotifModalOpen(true);
                } else {
                    setIsModalOpen(false);
                    setIsModalPrintOpen(true);
                }

                setIsModalOpen(false)
                //setIsModalPrintOpen(true)
            } 
            else {
                setErrorMsg('Erreur de validation de mot de passe.')
            }
        } 
        catch (error) {
            console.error("Erreur de validation:", error.message)
            setErrorMsg('Mot de passe incorrect')
        }
    }

    const formatDate2 = (date) => {
        const daysOfWeek = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
        const months = [
            'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 
            'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
        ];
        
        const dayName = daysOfWeek[date.getDay()]; // Nom du jour
        const day = date.getDate(); // Jour du mois
        const monthName = months[date.getMonth()]; // Nom du mois
        const year = date.getFullYear(); // Année
    
        return `${dayName} ${day} ${monthName} ${year}`; // Retourne la chaîne formatée
    }

    // Autorisation par mot de passe - Semaine dernière   
    const handlePasswordLastWeek = async (e) => {
        e.preventDefault()
        
        try {
            const userCredential = await signInWithEmailAndPassword(auth, auth.currentUser.email, password)
            const user = userCredential.user
    
            if (user.uid === uid) {
                const today = new Date()
                const lastWeekStart = new Date(today)
                //lastWeekStart.setDate(today.getDate() - today.getDay() - 7)
                lastWeekStart.setDate(today.getDate() - today.getDay() - 6)

                //const lastWeekEnd = new Date(today)
                //lastWeekEnd.setDate(today.getDate() - today.getDay() - 1)
                const lastWeekEnd = new Date(lastWeekStart);
                lastWeekEnd.setDate(lastWeekStart.getDate() + 4)
    
                // Récupére les feuilles de la semaine dernière
                const querySnapshot = await getDocs(query(
                    collection(db, 'feuillesDeRoute'),
                    where('userId', '==', uid),
                    where('date', '>=', Timestamp.fromDate(lastWeekStart)),
                    where('date', '<=', Timestamp.fromDate(lastWeekEnd))
                ))
    
                const existingDays = querySnapshot.docs.map(doc => {
                    return formatDate2(doc.data().date.toDate())
                })
    
                const missingDays = []

                //for (let dayOffset = 1; dayOffset < 5; dayOffset++) {
                for (let dayOffset = 0; dayOffset < 5; dayOffset++) {

                    const dayDate = new Date(lastWeekStart)
                    dayDate.setDate(lastWeekStart.getDate() + dayOffset)

                    const formattedDate = formatDate2(dayDate);
    
                    if (!existingDays.includes(formattedDate)) {
                        missingDays.push(formattedDate)
                    }
                }
    
                if (missingDays.length > 0) {
                    setMissingDaysQueue(missingDays)
                    setCurrentMissingDay(missingDays[0])
                    setMotifsForDays(Array(missingDays.length).fill(""))
                    setIsMotifModalOpenLastWeek(true)
                }
                else {
                    setopenModalLastWeek(false)
                    setIsModalPrintOpenLastWeek(true) 
                }
    
                setopenModalLastWeek(false)
                
            } 
        } 
        catch (error) {
            console.error("Erreur de validation:", error.message)
            setErrorMsg('Mot de passe incorrect')
        }
    }

    const handleMotifSubmitLastWeek = async () => {
        if (currentMissingDay) {
            const motifdate = new Date(currentMissingDay)
            const currentMotif = motifsForDays[missingDaysQueue.length - 1]

            if (!currentMotif) {
                alert("Veuillez fournir un motif pour ce jour.")
                return
            }

            try {
                await addDoc(collection(db, 'feuillesDeRoute'), {
                    userId: uid,
                    motif: currentMotif,
                    date: Timestamp.fromDate(motifdate),
                    isVisitsStarted: false,
                    isClotured: true,
                })

                missingDaysQueue.shift()

                const nextDay = missingDaysQueue[0]

                setMissingDaysQueue(missingDaysQueue)
                setCurrentMissingDay(nextDay || null)

                if (!nextDay) {
                    setIsMotifModalOpenLastWeek(false)
                    setIsModalPrintOpenLastWeek(true)
                } 
                fetchFeuillesRoute()
            } 
            catch (error) {
                console.error('Erreur lors de l’enregistrement du motif:', error)
            }
        }
    }

    const handleMotifSubmit = async () => {
        if (currentMissingDay) {

            const currentMotif = motifsForDays[missingDaysQueue.length - 1]

            if (!currentMotif) {
                alert("Veuillez fournir un motif pour ce jour.")
                return
            }

            const dateParts = currentMissingDay.split(" ");
            if (dateParts.length < 4) {
                console.error("Format de date invalide:", currentMissingDay);
                return;
            }
            
            const day = parseInt(dateParts[1], 10);
            const monthNames = {
                "janvier": 0, "février": 1, "mars": 2, "avril": 3, "mai": 4, "juin": 5,
                "juillet": 6, "août": 7, "septembre": 8, "octobre": 9, "novembre": 10, "décembre": 11
            };
            const month = monthNames[dateParts[2]];
            const year = parseInt(dateParts[3], 10);

            if (isNaN(day) || isNaN(month) || isNaN(year)) {
                console.error("Impossible de parser la date:", currentMissingDay);
                return;
            }

            const dateObject = new Date(Date.UTC(year, month, day, 0, 0, 0));
            dateObject.setHours(dateObject.getHours() + 1); 

            //console.log(dateObject)

            try {
                await addDoc(collection(db, 'feuillesDeRoute'), {
                    userId: uid,
                    motif: currentMotif,
                    date: dateObject,
                    isVisitsStarted: false,
                    isClotured: true,
                })

                missingDaysQueue.shift()

                const nextDay = missingDaysQueue[0]

                setMissingDaysQueue(missingDaysQueue)
                setCurrentMissingDay(nextDay || null)

                if (!nextDay) {
                    setIsMotifModalOpen(false)
                    setIsModalPrintOpen(true)
                } 

                fetchFeuillesRoute()
            } 
            catch (error) {
                console.error('Erreur lors de l’enregistrement du motif:', error)
            }
        }
    }

    // Le user n'a pas besoin de générer de feuille hebdo car pas de travail ni d'abscence à justifier
    const dontGenerateFeuilleHebdo = async () => {
        const now = new Date()
        const firstDayLastWeek = new Date(now.setDate(now.getDate() - now.getDay() - 7))
    
        await addDoc(collection(db, "fdrSemaine"), {
            userId: uid,
            work: false,
            dateSemaine: firstDayLastWeek,
        })

        window.location.reload()
    }

    // Enregistre la feuille hebdo dans la bdd 
    const handleSignFiche = async (startOfWeek) => {
        if (!signatureCanvasRef.current.isEmpty()) {
  
            try {
                const signatureDataUrl = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
                const timestamp = new Date()
                const newDoc = { date: timestamp, userId: uid, signature: signatureDataUrl, dateSemaine: startOfWeek, work: true }
    
                await addDoc(collection(db, 'fdrSemaine'), newDoc)

                setSignatureData({ url: signatureDataUrl, timestamp })
                setMessage("Veuillez télécharger votre feuille de route.")
                setIsDownloadDisplay(true)
            } 
            catch (error) {
                console.error("Erreur lors de l'ajout du document : ", error)
                setErrorNoSignature("Erreur lors de la sauvegarde de la signature. Veuillez réessayer.")
            }
        }
        else {
            setErrorMsg("Veuillez remplir et signer votre fiche");
        }
    }

    const generatePDF = (input, filename, signatureUrl) => {
        if (!input) {
            console.error('Erreur : référence à l\'élément non valide')
            return
        }
    
        html2canvas(input, {
            useCORS: true,
            scale: 2, 
        }).then(canvas => {

            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            const canvasWidth = canvas.width
            const canvasHeight = canvas.height
            const ratio = canvasWidth / canvasHeight
            let width = pdfWidth
            let marginLeft = 0

            if (window.innerWidth < 968) {
                marginLeft = 200 * (pdfWidth / canvasWidth) 
                width = pdfWidth - 2 * marginLeft 
            }
            const height = width / ratio
    
            let position = 0
    
            const addSignatureAndPageNumberToPDF = (pdf, pageHeight, pageNumber, totalPages) => {

                if (signatureUrl) {
                    const signatureImg = new Image()
                    signatureImg.src = signatureUrl

                    const signatureWidth = 15
                    const signatureHeight = 10
                    const xPos = pdfWidth - signatureWidth - 10
                    const yPos = pageHeight - signatureHeight - 10

                    pdf.addImage(signatureImg, 'PNG', xPos, yPos, signatureWidth, signatureHeight)
                }

                pdf.setFontSize(10)
                pdf.text(`Page ${pageNumber} / ${totalPages}`, pdfWidth / 2, pageHeight - 10, { align: 'center' })
            }
    
            if (height > pdfHeight) {
                const totalPages = Math.ceil(canvasHeight / (canvasWidth * pdfHeight / pdfWidth))

                for (let i = 0; i < totalPages; i++) {

                    const pageCanvas = document.createElement('canvas')
                    pageCanvas.width = canvasWidth
                    pageCanvas.height = canvasWidth * pdfHeight / pdfWidth

                    const pageContext = pageCanvas.getContext('2d')
                    pageContext.drawImage(canvas, 0, position, canvasWidth, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height)

                    const pageImgData = pageCanvas.toDataURL('image/png')

                    if (i > 0) {
                        pdf.addPage()
                    }

                    pdf.addImage(pageImgData, 'PNG', marginLeft, 0, width, pdfHeight)
                    addSignatureAndPageNumberToPDF(pdf, pdfHeight, i + 1, totalPages)
                    position += pageCanvas.height
                }
            } 
            else {
                pdf.addImage(imgData, 'PNG', marginLeft, 0, width, height)
                addSignatureAndPageNumberToPDF(pdf, height, 1, 1)
            }
    
            pdf.save(filename)

        }).catch(error => {
            console.error('Erreur lors de la génération du PDF :', error)
        })
    }
    
    const downloadPDF = () => {
        const input = pageRef.current
        const signatureUrl = signatureData ? signatureData.url : null

        generatePDF(input, "feuille-hebdomadaire.pdf", signatureUrl)
    }
    
    const handleDownloadDoc = () => {
        setIsDownloadDisplay(false)

        setTimeout(() => {
            downloadPDF()
        }, 1000)
        
        setTimeout(() => {
            setDownloadDone(true)
        }, 3000)
    }
    
    return (
        <div style={{ position: "relative" }}>
            <div className='titre-fiche'> 
                <h1>Feuille de route hebdomadaire</h1>
                <button onClick={onReturn} className="button-back"><img src={back} alt="retour" /></button>
            </div>

            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                <div className='hebdo-stats' style={{ marginBottom: '20px' , background: "#DCF1F2", borderRadius: "20px", display: "flex", padding: "10px 20px"}}>
                    <p style={{marginRight: "10px"}}>Nombre total de visites cette semaine <span>{visitCounts.totalVisits}</span></p>
                    <p style={{marginRight: "10px"}}>Nombre de visites client cette semaine <span>{visitCounts.clientVisits}</span></p>
                    <p style={{marginRight: "10px"}}>Nombre de visites prospect cette semaine <span>{visitCounts.prospectVisits}</span></p>
                </div>

                {Object.keys(currentWeek).map(weekKey => {
                    const { startOfWeek, endOfWeek, stopsByDay, totalKmByDay, motifsByDay } = currentWeek[weekKey]
                    const totalKmAll = Object.values(totalKmByDay).reduce((acc, km) => acc + km, 0)
                    const maxRows = Math.max(1, ...Object.values(stopsByDay).map(stops => stops.length),  ...days.map(day => motifsByDay[day].length > 0 ? 1 : 0))

                    return (
                        <div className='hebdo' key={weekKey}>
                            <h2>{`Semaine du ${formatDate(startOfWeek)} au ${formatDate(endOfWeek)}`}</h2>

                            <table border="1" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: "20px", fontSize: "15px" }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%', background: "#3D9B9B", color: "white" }}>Total <strong>{formatDistance(totalKmAll)}</strong></th>
                                        {days.map(day => (
                                            <th key={day} style={{ width: '18%', background: "#c7c7c7" }}>{day}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {Array.from({ length: maxRows }).map((_, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td style={{ verticalAlign: 'top', background: "#c7c7c7" }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: "5px" }}>
                                                    <strong>Nom:</strong> <br />
                                                    <strong>Prosp/Clt:</strong> <br />
                                                    <strong>Ville:</strong><br />
                                                    <strong>Km:</strong><br />
                                                    <strong>Arrivée:</strong><br />
                                                    <strong>Départ:</strong>
                                                </div>
                                            </td>

                                            {days.map(day => (
                                                <td key={day} style={{ verticalAlign: 'top' }}>
                                                    {stopsByDay[day][rowIndex] ? (
                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: "5px", height: "100%", fontSize: "14px" }}>
                                                            <p><strong>{stopsByDay[day][rowIndex].name}</strong></p><br />
                                                            <p>{stopsByDay[day][rowIndex].status}</p><br />
                                                            <p>{stopsByDay[day][rowIndex].address} {stopsByDay[day][rowIndex].postalCode} {stopsByDay[day][rowIndex].city}</p><br />
                                                            <p>{formatDistance(stopsByDay[day][rowIndex].distance)}</p><br />
                                                            <p>{stopsByDay[day][rowIndex].arrivalTime}</p><br />
                                                            <p>{stopsByDay[day][rowIndex].departureTime}</p><br />
                                                        </div>
                                                    ) : (
                                                        <div style={{ height: "220px", background: "#e0e0e0", padding: "10px", textAlign: "center" }}>
                                                            {rowIndex === 0 && motifsByDay[day].length > 0 && (
                                                                <div>
                                                                    <p style={{marginBottom: "5px"}}>Pas de déplacements</p>
                                                                    <p>Motif : <strong>{motifsByDay[day][0]}</strong></p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                    <tr>
                                        <td style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                            <strong>Total km</strong>
                                        </td>
                                        {days.map(day => (
                                            <td key={day} style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                <strong>{formatDistance(totalKmByDay[day])}</strong>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })}
                
                <button onClick={handleValidate} className='button-colored'>Valider les déplacements de la semaine</button>
            </div>

            {noFicheHebdoLastWeek && (
                <div className='modal'>
                    <div className='modal-content'>
                        <p>Vous n'avez pas signé votre feuille de route hebdomadaire la semaine dernière.</p>
                        <p>Cliquez sur <strong>"Signer"</strong> si vous avez travaillé ou que vous devez fournir un motif d'abscence.</p>
                        <p>Cliquez sur <strong>"Fermer"</strong> si vous n'avez pas besoin de générer une feuille hebdomadaire.</p>
                        <div style={{display: "flex", marginTop: "20px", justifyContent: "center"}}> 
                             
                            <button className='button-colored' style={{margin: "10px", borderRadius: "5px", padding: "8px 15px", width: "initial"}} onClick={() => { setopenModalLastWeek(true); setNoFicheHebdoLastWeek(false)} }>Signer</button>
                            <button className='button-colored' style={{margin: "10px", borderRadius: "5px", padding: "8px 15px", width: "initial"}} onClick={() => { setNoFicheHebdoLastWeek(false); dontGenerateFeuilleHebdo()} }>Fermer</button>
                        </div>
                    </div>
                </div>
            )}

            {openModalLastWeek && (
                <div className='modal'> 
                    <div className='modal-content'>
                        <p style={{marginBottom: "30px"}}>Veuillez confirmer votre mot de passe.</p>
                        <form onSubmit={handlePasswordLastWeek}>
                            <input type="password" placeholder="Votre mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button style={{marginTop: "10px"}}  type="submit" className='button-colored'>confirmer</button>
                            <p onClick={() => setopenModalLastWeek(false)} style={{fontSize: "14px", marginTop: "20px", color: "#3D9B9B", cursor: "pointer"}}>Annuler</p>
                            {errorMsg && <p>{errorMsg}</p>}
                        </form>
                    </div>
                </div>
            )}

            {isMotifModalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <p style={{marginBottom: "20px"}}>Vous n'avez pas effectué de déplacements le {currentMissingDay}.</p>
                        <p style={{marginBottom: "20px"}}>Veuillez fournir un motif.</p>
                        <input type="text" placeholder="Entrez le motif" value={motifsForDays[missingDaysQueue.length - 1] || ""}
                            onChange={(e) => { const updatedMotifs = [...motifsForDays]; updatedMotifs[missingDaysQueue.length - 1] = e.target.value; setMotifsForDays(updatedMotifs) }}
                            style={{ borderBottomWidth: 1, marginBottom: 20 }} 
                        />
                        <button className='button-colored' onClick={handleMotifSubmit}>Valider</button>
                    </div>
                </div>
            )}

{isMotifModalOpenLastWeek && (
                <div className='modal'>
                    <div className='modal-content'>
                        <p style={{marginBottom: "20px"}}>Vous n'avez pas effectué de déplacements le {currentMissingDay}.</p>
                        <p style={{marginBottom: "20px"}}>Veuillez fournir un motif.</p>
                        <input type="text" placeholder="Entrez le motif" value={motifsForDays[missingDaysQueue.length - 1] || ""}
                            onChange={(e) => { const updatedMotifs = [...motifsForDays]; updatedMotifs[missingDaysQueue.length - 1] = e.target.value; setMotifsForDays(updatedMotifs) }}
                            style={{ borderBottomWidth: 1, marginBottom: 20 }} 
                        />
                        <button className='button-colored' onClick={handleMotifSubmitLastWeek}>Valider</button>
                    </div>
                </div>
            )}
            
            {isModalOpen && (
                <div className='modal' style={{position: "fixed", top: "0", left: "0", width: "100%", height: "100%", background: "#b4b4b48a", display: "flex", justifyContent: "center", alignItems: "center"}}>  
                    <div className='modal-content' style={{background: "white", padding: "40px", paddingBottom: "20px", textAlign: "center", borderRadius: "20px"}}> 
                        <form onSubmit={handlePassword}>
                            <p style={{paddingBottom: "30px"}}>Veuillez confirmez votre mot de passe.</p>
                            <input type="password" placeholder="Votre mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button style={{marginTop: "10px"}}  type="submit" className='button-colored'>confirmer</button>
                            <p onClick={() => setIsModalOpen(false)} style={{fontSize: "14px", marginTop: "20px", color: "#3D9B9B", cursor: "pointer"}}>Annuler</p>
                            {errorMsg && <p>{errorMsg}</p>}
                        </form>
                    </div>  
                </div>
            )} 

            {isModalPrintOpen && ( 
                <div  style={{ position: "absolute", width: "100%", height: "100%", background: "white", top: "0", left: "0", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className='print-page' ref={pageRef} style={{padding: "50px", display: "flex", flexDirection: "column", alignItems: "center",  width: "60%"}}>  
                        <h1 style={{ fontSize: "20px", marginBottom: "30px", marginTop: "30px", textAlign: "center" }}>Validation de la feuille de route hebdomadaire</h1>
                        <p className='soussigne' style={{ lineHeight: "25px", marginBottom: "30px", textAlign: "center", width: "70%" }}>
                            Je soussigné(e) <strong>{usersMap[feuillesRoute[0]?.userId]?.firstname} {usersMap[feuillesRoute[0]?.userId]?.lastname}</strong>, certifie l’authenticité des informations figurant sur la feuille de route ci-dessous qui rend compte des déplacements professionnels que j’ai effectué concernant la semaine du <strong>{formatDate(currentWeek[Object.keys(currentWeek)[0]]?.startOfWeek)}</strong> au <strong>{formatDate(currentWeek[Object.keys(currentWeek)[0]]?.endOfWeek)}</strong>.
                        </p>
                        
                        {Object.keys(currentWeek).map(weekKey => {
                            console.log(currentWeek)
                            const { startOfWeek, endOfWeek, stopsByDay, totalKmByDay, motifsByDay } = currentWeek[weekKey]
                            const totalKmAll = Object.values(totalKmByDay).reduce((acc, km) => acc + km, 0)
                            const maxRows = Math.max(1, ...Object.values(stopsByDay).map(stops => stops.length),  ...days.map(day => motifsByDay[day].length > 0 ? 1 : 0))

                            return (
                                <div style={{width: "100%"}} className='hebdo' key={weekKey}>
                                    <h2>{`Semaine du ${formatDate(startOfWeek)} au ${formatDate(endOfWeek)}`}</h2>

                                    <table border="1" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: "20px" }}>
                                        <thead style={{fontSize: "10px"}}>
                                            <tr>
                                                <th style={{ width: '10%', background: "#3D9B9B", color: "white" }}>Total <strong>{formatDistance(totalKmAll)}</strong></th>
                                                {days.map(day => (
                                                    <th key={day} style={{ width: '18%', background: "#c7c7c7" }}>{day}</th>
                                                ))}
                                            </tr>
                                        </thead>

                                        <tbody style={{fontSize: "10px"}}>
                                            {Array.from({ length: maxRows }).map((_, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td style={{ verticalAlign: 'top', background: "#c7c7c7" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
                                                            <strong>Nom</strong> 
                                                            <strong>Km</strong>
                                                        </div>
                                                    </td>

                                                    {days.map(day => (
                                                        <td key={day} style={{ verticalAlign: 'top', fontSize: "10px" }}>
                                                            {stopsByDay[day][rowIndex] ? (
                                                                <div style={{ display: "flex", fontSize: "10px", flexDirection: "column", padding: "5px", height: "100%"}}>
                                                                    <p><strong>{stopsByDay[day][rowIndex].name}</strong></p>
                                                                    <p>{formatDistance(stopsByDay[day][rowIndex].distance)}</p>
                                                                </div>
                                                            ) : (
                                                                <div style={{  fontSize: "10px", background: "#e0e0e0", padding: "10px", textAlign: "center" }}>
                                                                    {rowIndex === 0 && motifsByDay[day].length > 0 && (
                                                                        <div>
                                                                            <p style={{marginBottom: "5px"}}>Pas de déplacements</p>
                                                                            <p>Motif : <strong>{motifsByDay[day][0]}</strong></p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}

                                            <tr>
                                                <td style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                    <strong>Total km</strong>
                                                </td>

                                                {days.map(day => (
                                                    <td key={day} style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                        <strong>{formatDistance(totalKmByDay[day])}</strong>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}

                        <div className='hebdo-stats-print'>
                            <p>Nombre total de visites <span>{visitCounts.totalVisits}</span></p>
                            <p>Nombre de visites clients <span>{visitCounts.clientVisits}</span></p>
                            <p>Nombre de visites prospects <span>{visitCounts.prospectVisits}</span></p>
                        </div>
                   
                        {signatureData ? (
                            <div>
                                <img style={{marginBottom: "20px", marginTop: "30px"}} src={signatureData.url} alt="Signature" />
                                <p style={{marginBottom: "20px"}}>Signé le <strong>{formatDate(new Date(signatureData.timestamp))}</strong> à <strong>{new Date(signatureData.timestamp).toLocaleTimeString('fr-FR')}</strong></p>
                            </div>
                        )  : (   
                            <div className='signature'>
                                <p style={{marginBottom : "5px", marginTop: "20px", textAlign: "center"}} className='error-message'>Veuillez signer votre feuille de route hebdomadaire dans le cadre ci-dessous.</p>
                                <p className='error-message'>{errorNoSignature}</p>
                                <ReactSignatureCanvas ref={signatureCanvasRef} canvasProps={{ width: 200, height: 120, className: 'signature-fdr' }} />
                                <button style={{width: "200px"}} className='button-colored' onClick={() => handleSignFiche(currentWeek[Object.keys(currentWeek)[0]]?.startOfWeek)}>Valider</button>
                            </div>
                        )}

                        {isDownloadDisplay && (
                            <div className='modal'>
                                <div className='modal-content'>
                                    <p style={{marginBottom: "20px", padding: "5px"}}>{message}</p>
                                    <button onClick={handleDownloadDoc} className='button-colored'>Télécharger</button>
                                </div>
                            </div>
                        )}

                        {downloadDone && (
                            <button className='button-colored' onClick={() => setIsFinalModal(true)} >Terminer</button>
                        )}

                        {isFinalModal && (
                            <div className='modal'>
                                <div className='modal-content'>
                                    <p style={{marginBottom: "20px"}}>N'oubliez pas d'envoyer votre feuilles de route hebdomadaire par e-mail à l'adresse suivante : </p>
                                    <p style={{marginBottom: "30px", fontWeight: "bold"}}>fdr@dsh-application.com</p>
                                    <button style={{marginBottom: "20px"}} onClick={onReturn} className='button-colored'>Fermer</button> 
                                </div>
                            </div>
                        )} 
                    </div> 
                </div>
            )}

            {isModalPrintOpenLastWeek && ( 
                <div style={{ position: "absolute", width: "100%", height: "100%", background: "white", top: "0", left: "0", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className='print-page' ref={pageRef} style={{ padding: "50px", display: "flex", flexDirection: "column", alignItems: "center", width: "60%" }}>  
                        <h1 style={{ fontSize: "20px", marginBottom: "30px", marginTop: "30px", textAlign: "center" }}>Validation de la feuille de route hebdomadaire</h1>
                        <p className='soussigne' style={{ lineHeight: "25px", marginBottom: "30px", textAlign: "center", width: "70%" }}>
                        Je soussigné(e) <strong>{usersMap[feuillesRoute[0]?.userId]?.firstname} {usersMap[feuillesRoute[0]?.userId]?.lastname}</strong>, certifie l’authenticité des informations figurant sur la feuille de route ci-dessous qui rend compte des déplacements professionnels effectués concernant la semaine du <strong>{formatDate(lastWeek[Object.keys(lastWeek)[0]]?.startOfWeek)}</strong> au <strong>{formatDate(lastWeek[Object.keys(lastWeek)[0]]?.endOfWeek)}</strong>.
                        </p>

                        {Object.keys(lastWeek).map(weekKey => {
                            const { startOfWeek, endOfWeek, stopsByDay, totalKmByDay, motifsByDay } = lastWeek[weekKey]
                            const totalKmAll = Object.values(totalKmByDay).reduce((acc, km) => acc + km, 0)
                            const maxRows = Math.max(1, ...Object.values(stopsByDay).map(stops => stops.length),  ...days.map(day => motifsByDay[day].length > 0 ? 1 : 0))

                            return (
                                <div style={{ width: "100%" }} className='hebdo' key={weekKey}>
                                    <h2>{`Semaine du ${formatDate(startOfWeek)} au ${formatDate(endOfWeek)}`}</h2>

                                    <table border="1" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: "20px" }}>
                                        <thead style={{ fontSize: "10px" }}>
                                            <tr>
                                                <th style={{ width: '10%', background: "#3D9B9B", color: "white" }}>Total <strong>{formatDistance(totalKmAll)}</strong></th>
                                                {days.map(day => (
                                                    <th key={day} style={{ width: '18%', background: "#c7c7c7" }}>{day}</th>
                                                ))}
                                            </tr>
                                        </thead>

                                        <tbody style={{ fontSize: "10px" }}>
                                            {Array.from({ length: maxRows }).map((_, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td style={{ verticalAlign: 'top', background: "#c7c7c7" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
                                                            <strong>Nom</strong> 
                                                            <strong>Km</strong>
                                                        </div>
                                                    </td>

                                                    {days.map(day => (
                                                        <td key={day} style={{ verticalAlign: 'top', fontSize: "10px" }}>
                                                            {stopsByDay[day][rowIndex] ? (
                                                                <div style={{ display: "flex", fontSize: "10px", flexDirection: "column", padding: "5px", height: "100%"}}>
                                                                    <p><strong>{stopsByDay[day][rowIndex].name}</strong></p>
                                                                    <p>{formatDistance(stopsByDay[day][rowIndex].distance)}</p>
                                                                </div>
                                                            ) : (
                                                                <div style={{ fontSize: "10px", background: "#e0e0e0", padding: "5px", textAlign: "center" }}>
                                                                    {rowIndex === 0 && motifsByDay[day].length > 0 && (
                                                                        <div>
                                                                            <p>Pas de déplacements</p>
                                                                            <p>Motif : <strong>{motifsByDay[day][0]}</strong></p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}

                                            <tr>
                                                <td style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                    <strong>Total km</strong>
                                                </td>

                                                {days.map(day => (
                                                    <td key={day} style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                        <strong>{formatDistance(totalKmByDay[day])}</strong>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}   

                        <div className='hebdo-stats-print'>
                            <p>Nombre total de visites <span>{visitCounts.totalVisitsLastWeek}</span></p>
                            <p>Nombre de visites clients <span>{visitCounts.clientVisitsLastWeek}</span></p>
                            <p>Nombre de visites prospects <span>{visitCounts.prospectVisitsLastWeek}</span></p>
                        </div>
                   
                        {signatureData ? (
                            <div>
                                <img style={{marginBottom: "20px", marginTop: "30px"}} src={signatureData.url} alt="Signature" />
                                <p style={{marginBottom: "20px"}}>Signé le <strong>{formatDate(new Date(signatureData.timestamp))}</strong> à <strong>{new Date(signatureData.timestamp).toLocaleTimeString('fr-FR')}</strong></p>
                            </div>
                        )  : (   
                            <div className='signature'>
                                <p style={{marginBottom : "5px", marginTop: "20px", textAlign: "center"}} className='error-message'>Veuillez signer votre feuille de route hebdomadaire dans le cadre ci-dessous.</p>
                                <p className='error-message'>{errorNoSignature}</p>
                                <ReactSignatureCanvas ref={signatureCanvasRef} canvasProps={{ width: 200, height: 120, className: 'signature-fdr' }} />
                                <button style={{width: "200px"}} className='button-colored' onClick={() => handleSignFiche(lastWeek[Object.keys(lastWeek)[0]]?.startOfWeek)}>Valider</button>
                            </div>
                        )}

                        {isDownloadDisplay && (
                            <div className='modal'>
                                <div className='modal-content'>
                                    <p style={{marginBottom: "20px", padding: "5px"}}>{message}</p>
                                    <button onClick={handleDownloadDoc} className='button-colored'>Télécharger</button>
                                </div>
                            </div>
                        )}

                        {downloadDone && (
                            <button style={{marginBottom: "20px"}} className='button-colored' onClick={() => setIsFinalModal(true)} >Terminer</button>
                        )}

                        {isFinalModal && (
                            <div className='modal'>
                                <div className='modal-content'>
                                    <p style={{marginBottom: "20px"}}>N'oubliez pas d'envoyer votre feuilles de route hebdomadaire par e-mail à l'adresse suivante : </p>
                                    <p style={{marginBottom: "30px", fontWeight: "bold"}}>fdr@dsh-application.com</p>
                                    <button style={{marginBottom: "20px"}} onClick={() => window.location.reload()} className='button-colored'>Fermer</button> 
                                </div>
                            </div>
                        )}
                    </div> 
                </div>
            )}

            {modalError && (
                <div className='modal'>
                    <div className='modal-content'>
                        <p style={{marginBottom: "20px"}}>Vous pouvez signer votre feuille de route chaque vendredi entre 17h et 23h.</p>
                        <button onClick={() => setModalError(false)} className='button-colored'>Fermer</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FeuillesHebdo